import { useOrder } from 'components/OrderContext';
import { Form, useFormikContext } from 'formik';
import People from '../People';
import PaymentInfo from '../PaymentInfo';
import Waitlist from '../Waitlist';
import config from 'config';
const { WAITLIST_MODE } = config;

export default function FormContents() {
  const { updateOrder, currentPage, setCurrentPage } = useOrder();
  const formik = useFormikContext();
  const { values } = formik;

  function handleClickBackButton() {
    updateOrder(values);
    formik.setSubmitting(false);
    setCurrentPage(currentPage - 1);
  }

  return(
    <Form spellCheck='false'>
      {currentPage === 1 && <People />}
      {currentPage === 2 && WAITLIST_MODE && <Waitlist handleClickBackButton={handleClickBackButton} />}
      {currentPage === 2 && !WAITLIST_MODE && <PaymentInfo handleClickBackButton={handleClickBackButton} />}
    </Form>
  );
}
